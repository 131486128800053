import {Table} from "antd";
import React from "react";
import OlympusServices from "../services/taiga/OlympusServices";
import StandardLayout from '../layouts/StandardLayout'
import moment from "moment";

const columns = [
    {
        title: 'Name',
        dataIndex: 'project',
        key: 'project',
    },
    {
        title: 'SC đã chạy',
        dataIndex: 'status',
        key: 'status',
        align: 'right',
        width: 160
    },
    {
        title: 'Start time',
        dataIndex: 'start_time',
        align: 'right',
        render: (text,row) => {
            return moment(new Date(Number.parseInt(row.start_time))).format("HH:mm DD/MM/yyyy")
        },
        width: 200
    },
    {
        title: 'Tags',
        dataIndex: 'tags',
    },
    {
        title: 'Report',
        dataIndex: 'url',
        render: (text, row) => {
            return <a href={row.url}>Xem báo cáo (nếu đã xong)</a>
        }
    }
];


export default class TestProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        //load progress here
        OlympusServices.getProgress().then((result)=> {
            this.setState({ data: result.body.list })
        })
    }

    render() {
        return <StandardLayout {...this.props}>
            <Table
                columns={columns}
                dataSource={this.state.data}
                rowKey={(row)=>{return row.identity}}
                pagination={false}
            />
        </StandardLayout>
    }
}