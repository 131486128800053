import TaigaClient from "../TaigaClient";
import {LocalStore} from "../../utils/LocalStore";
import OlympusServices from "./OlympusServices";
import Cache from 'js-cache'

export default class TaigaServices {
    token = '';

    cache = new Cache();

    setToken(token) {
        this.token = token
    }

    constructor(accessToken) {
        this.token = accessToken
    }

    async login(username, password) {
        return TaigaClient.requestPromise({
            endpoint: 'https://agile.gobizdev.com/api/v1/auth',
            body: {
                username: username,
                password: password,
                type: 'normal'
            },
            method: 'POST'
        })
    }

    olympusService() {
        return new OlympusServices(this.token);
    }

    static handleResponse = (response) => {
        if (response.status === 401) {
            LocalStore.getInstance().save('taiga_session', null)
        }
    };

    static instance;

    static getDefaultInstance(token) {
        if (!this.instance) {
            this.instance = new TaigaServices()
        }
        if (!token) {
            let profile = LocalStore.getInstance().read('taiga_session');
            if (profile)
                token = profile.auth_token;
        }

        this.instance.setToken(token);

        return this.instance
    }

    static clearSession() {
        LocalStore.getInstance().save('taiga_session', null)
    }

    //region -- logical --
    async updateTaskOfTeamPriorityToMatchEpic(userStory, epicId, project = null) {
        if (!project) {
            project = (await this.getProject(76)).body
        }

        let userStories = (await this.getUserStoriesByEpic(epicId)).body;

        let closed = project.task_statuses.find(t => t.is_closed);
        let open = project.task_statuses.find(t => !t.is_closed);

        let promies = [];
        let tasks = (await this.getTasksByUserStory(userStory.id)).body;

        userStories.forEach((us) => {
            if (us.project === 76) return;

            let task = tasks.find(t => t.subject.indexOf(`#${us.ref}:`) > -1);
            if (!task) {
                //create task
                promies.push(this.createTask({
                    project: 76,
                    subject: `US #${us.ref}: ${us.subject}`,
                    user_story: userStory.id,
                    status: us.status_extra_info.is_closed ? closed.id : open.id
                }));
            } else {
                promies.push(this.patchTask(task.id, {
                    version: task.version,
                    subject: `US #${us.ref}: ${us.subject}`,
                    status: us.status_extra_info.is_closed ? closed.id : open.id
                }))
            }
        });

        return await Promise.all(promies);
    }

    async moveUserStoriesToBefore(list, userStory) {
        let stories = [];
        for (let ii = 0; ii < list.length; ii++) {

            // cần đảm bảo các user story trong list nằm trên cùng project thì mới sắp xếp được
            if (list[ii].project !== userStory.project) continue;
            let story = {
                us_id: list[ii].id,
                order: userStory.backlog_order - list.length + ii
            };
            stories.push(story);
        }
        return await this.bulkUpdateBacklogOrder(userStory.projectId, stories);
    }
    //endregion

    //region -- User Stories --
    getUserStoriesInBacklog(projectId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories?milestone=null&no-milestone=1&page=1&project=${projectId}`,
                // headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getAllUserStoriesInBacklog(projectId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories?milestone=null&no-milestone=1&page=1&project=${projectId}`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getBacklogUserStoriesInEpic(epicId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories?epic=${epicId}&milestone=null&no-milestone=1&include_tasks=true&order_by=epic_order`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getUserStoriesInKanban(projectId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories?include_attachments=1&include_tasks=1&project=${projectId}&status__is_archived=false`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getUserStoriesByEpic(epicId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories?epic=${epicId}&include_tasks=true&order_by=epic_order`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getUserStoriesByEpicAndProject(epicId, projectId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories?epic=${epicId}&project=${projectId}&include_tasks=false&order_by=epic_order`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getUserStoriesBySprint(sprintId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories?milestone=${sprintId}`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getUserStoryById(userStoryId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories/${userStoryId}`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        );
    }

    bulkUpdateBacklogOrder(projectId, stories) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories/bulk_update_backlog_order`,
                method: 'POST',
                body: {
                    project_id: projectId,
                    bulk_stories: stories
                }
            }, this.token, TaigaServices.handleResponse
        )
    }

    bulkUpdateKanbanOrder(projectId, stories, statusId, swimlaneId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories/bulk_update_kanban_order`,
                method: 'POST',
                body: {
                    project_id: projectId,
                    bulk_userstories: stories,
                    status_id: statusId,
                    swimlane_id: swimlaneId || undefined
                }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getCustomAttributes(userStoryId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1//userstories/custom-attributes-values/${userStoryId}`,
            }, this.token, TaigaServices.handleResponse
            )
    }

    patchCustomAttributes(userStoryId, version, attributeId, value) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1//userstories/custom-attributes-values/${userStoryId}`,
                method: 'PATCH',
                body: {
                    version: version,
                    attributes_values: {
                        [attributeId]: value
                    }
                }
            }, this.token, TaigaServices.handleResponse
        )
    }

    deleteUserStory(userStoryId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1//userstories/${userStoryId}`,
                method: 'DELETE'
            }, this.token, TaigaServices.handleResponse
        )
    }

    getProjectTags(projectId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/projects/${projectId}/tags_colors`,
            }, this.token, TaigaServices.handleResponse, 10000
        )
    }

    postNewUserStory(body) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/userstories`,
                method: 'POST',
                body: body
            }, this.token, TaigaServices.handleResponse
        )
    }

    changeUserStoryStatus(userStoryId, statusId, version) {
        return this.patchUserStory(userStoryId, {
            'status': statusId,
            'version': version
        });
    }

    patchUserStory(id, body) {
        return TaigaClient.requestPromise(
            {
                method: 'PATCH',
                endpoint: `https://agile.gobizdev.com/api/v1/userstories/${id}`,
                body: body
            }, this.token, TaigaServices.handleResponse
        )
    }
    //endregion

    //region -- Tasks --
    createTask(task) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/tasks`,
                body: task,
                method: 'POST'
            }, this.token, TaigaServices.handleResponse
        )
    }

    patchTask(id, body) {
        return TaigaClient.requestPromise(
            {
                method: 'PATCH',
                endpoint: `https://agile.gobizdev.com/api/v1/tasks/${id}`,
                body: body
            }, this.token, TaigaServices.handleResponse
        )
    }

    getTasksBySprint(projectId, sprintId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/tasks?include_attachments=0&milestone=${sprintId}&order_by=us_order&project=${projectId}&q=`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getTasksByUserStory(userStoryId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/tasks?user_story=${userStoryId}`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getUnfinishedTask(assigned_to) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/tasks?assigned_to=${assigned_to}&status__is_closed=false`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    changeDueDate(id, date, version) {
        return this.patchTask(id, {
            'due_date': date,
            'version': version
        });
    }

    assignTask(id, to, version) {
        return this.patchTask(id, {
            'assigned_to': to || null,
            'version': version
        });
    }

    changeStatus(id, statusId, version) {
        return this.patchTask(id, {
            'status': statusId,
            'version': version
        });
    }
    //endregion

    //region -- Projects --
    getProjects(memberId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/projects?member=${memberId}&order_by=user_order&slight=true`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse, 600000
        )
    }

    getMyProjects() {
        let profile = LocalStore.getInstance().read('taiga_session');
        return this.getProjects(profile.id);
    }

    getProject(projectId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/projects/${projectId}`
            }, this.token, TaigaServices.handleResponse, 300000
        )
    }

    getSprints(projectId, closed = false) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/milestones?closed=${closed}&project=${projectId}`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getSprint(id) {
        return TaigaClient.requestPromise(
            {
                endpoint : `https://agile.gobizdev.com/api/v1/milestones/${id}`
            }, this.token, TaigaServices.handleResponse
        )
    }
    //endregion
    
    //region -- Epic --
    getEpics(projectId, isClosed = null) {
        let query = '';
        if (isClosed !==null) {
            query+='&status__is_closed='+ isClosed
        }
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/epics?project=${projectId}${query}`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse, 60000
        )
    }
    
    changeEpicStatus(epic, statusId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/epics/${epic.id}`,
                method: 'PATCH',
                body: {
                    version: epic.version,
                    status: statusId
                }
            }, this.token, TaigaServices.handleResponse
        )
    }

    linkEpic(epicId, userStoryId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/epics/${epicId}/related_userstories`,
                method: 'POST',
                body: {
                    epic: epicId,
                    user_story: userStoryId
                }
            }, this.token, TaigaServices.handleResponse
        );
    }
    //endregion

    //region -- membership --
    getMembership(projectId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/memberships?project=${projectId}`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    deleteMembership(id) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/memberships/${id}`,
                method: 'DELETE'
            }, this.token, TaigaServices.handleResponse
        )
    }

    createMembership(projectId, roleId, user) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/memberships`,
                method: 'POST',
                body: {
                    project: projectId,
                    role: roleId,
                    username: user
                }
            }, this.token, TaigaServices.handleResponse
        )
    }

    getRoles(projectId) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/roles?project=${projectId}`,
                headers: { 'x-disable-pagination': 1 }
            }, this.token, TaigaServices.handleResponse
        )
    }

    patchMembership(id, body) {
        return TaigaClient.requestPromise(
            {
                endpoint: `https://agile.gobizdev.com/api/v1/memberships/${id}`,
                method: 'PATCH',
                body: body
            }, this.token, TaigaServices.handleResponse
        )
    }
    //endregion
}