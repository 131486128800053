import React from 'react';
import {
    LoadingOutlined
} from '@ant-design/icons';

class Loading extends React.Component {
    render() {
        return <div style={{margin: '0 auto', textAlign: 'center'}}>
            <div style={{maxWidth: '600px', marginTop: '200px', padding: '60px'}} className='box chanting'>
            <LoadingOutlined/><br/>
                {this.props.message ? this.props.message : <div>Almighty protector of Gobiz and its Mount, Olympus, I beg of thee, please heed my cry. Transform thyself from orb of light and bring me victory in this fight. I beseech thee, grace our humble game. But first I shall...call out thy name, <strong>CHAOS</strong>.</div>}
            </div>
        </div>
    }
}

export default Loading;