import React from 'react';
import StandardLayout from '../layouts/StandardLayout'
import { Row, Col, Alert, Select, Form } from "antd";
import { Radar } from '@ant-design/charts';
import { DataSet } from '@antv/data-set';

const {Option} = Select;
const layout = {
    labelCol: {span: 12},
    wrapperCol: {span: 12},
};

const levels = {
    'technology': {
        1: {
            name: 'Adopts',
            description: 'có thể chủ động học và đi theo các công cụ, công nghệ, kỹ thuật mà team sử dụng'
        },
        2: {
            name: 'Specializes',
            description: 'nắm rõ đủ để giải đáp thắc mắc cho người mới về một hay nhiều công nghệ team đang sử dụng, và chủ động học hỏi thêm những cái mới'
        },
        3: {
            name: 'Evangelizes',
            description: 'có thể tự tìm hiểu, làm POC và đưa công nghệ/kỹ thuật mới về team'
        },
        4: {
            name: 'Masters',
            description: 'có hiểu biết sâu sắc về toàn bộ stack công nghệ của hệ thống và có khả năng thay đổi, tùy biến tùy mục đích sản phẩm'
        },
        5: {
            name: 'Creates',
            description: 'có khả năng thiết kế hoặc tạo ra kỹ thuật/công nghệ mà có khả năng sử dụng rộng rãi kể cả trong cũng như ngoài team'
        },
    },
    'system': {
        1: {
            name: 'Enhances',
            description: 'lập trình, xây dựng, kiểm thử được chức năng và fix bug để mở rộng hệ thống, sản phẩm'
        },
        2: {
            name: 'Designs',
            description: 'có khả năng tự thiết kế và làm những chức năng cỡ trung hoặc lớn, có khả năng giải quyết nợ kỹ thuật. Đối với manual tester là xác định được sự liên quan giữa các chức năng, nắm được cách hệ thống vận hành (thông qua API, kafka, database,...)'
        },
        3: {
            name: 'Owns',
            description: 'Hiểu biết sâu sắc về cách hệ thống vận hành và có khả năng kiểm soát performance, kiểm soát lỗi toàn hệ thống. Đối với tester là ngoại trừ kiểm thử chức năng thì hiểu và có khả năng thực thi thêm một loại test khác như bảo mật, performance - nhưng không cần chuyên sâu'
        },
        4: {
            name: 'Evolves',
            description: 'có khả năng nâng cấp kiến trúc để đáp ứng những yêu cầu trong tương lai và định nghĩa được các chỉ số vận hành. Đối với tester định nghĩa được các tiêu chí kiểm thử.'
        },
        5: {
            name: 'Leads',
            description: 'dẫn dắt xây dựng hệ thống và xây dựng kế hoạch kế hoạch, sẵn sàng phản ứng và có thể phòng chống các sự cố xảy ra'
        },
    },
    'product': {
        1: {
            name: 'Copycat',
            description: 'có khả năng học tập những chức năng, sản phẩm đã có, hoặc thiết kế sản phẩm đúng theo yêu cầu đặt ra'
        },
        2: {
            name: 'Solves',
            description: 'có thể xây dựng sản phẩm theo hướng giải quyết được vấn đề, chứ không chỉ là theo đúng yêu cầu mà người dùng đặt ra'
        },
        3: {
            name: 'Processes',
            description: 'có thể xây dựng, thiết kế sản phẩm theo cả một chuỗi quy trình nhằm giải quyết 1 nghiệp vụ chứ không chỉ là giải quyết theo từng chức năng'
        },
        4: {
            name: 'Commercials',
            description: 'xây dựng được cả 1 bộ sản phẩm có khả năng thương mại hóa (bao gồm 1 hay nhiều M), có khả năng bán được cho khách hàng'
        },
        5: {
            name: 'Creates',
            description: 'dẫn dắt tạo ra nhu cầu, định hướng xây dựng 1 sản phẩm hoàn toàn mới dựa trên tham khảo nhu cầu thị trường, người dùng'
        },
    },
    'people': {
        1: {
            name: 'Learns',
            description: 'nhanh chóng học hỏi, có thể chủ động trong công việc'
        },
        2: {
            name: 'Supports',
            description: 'sẵn sàng hỗ trợ các thành viên khác trong team và hỗ trợ để họ hoàn thành công việc, có sự phát triển'
        },
        3: {
            name: 'Mentors',
            description: 'hướng dẫn, định hướng, đào tạo những thành viên khác để họ phát triển; động viên khích lệ họ khi cần'
        },
        4: {
            name: 'Coordinates',
            description: 'có khả năng điều phối toàn team để nhận được những trao đổi, thảo luận có ích, nhận được phản hồi mang tính xây dựng'
        },
        5: {
            name: 'Manages',
            description: 'quản lý được team, bao gồm sắp xếp được vị trí, hiểu được mong muốn, xác định được hiệu suất, hiểu được tâm lý nhân sự'
        },
    },
    'process': {
        1: {
            name: 'Follows',
            description: 'theo được quy trình của team, có tinh thần trách nhiệm để deliver được kết quả như cam kết với đồng đội'
        },
        2: {
            name: 'Enforces',
            description: 'giúp được cả những thành viên khác tuân thủ quy trình, giúp mọi người hiểu được lợi ích cũng như điểm yếu của quy trình'
        },
        3: {
            name: 'Challenges',
            description: 'có thể đưa ra và áp dụng những cải tiến giúp quy trình hoạt động tốt hơn (một cách thường xuyên)'
        },
        4: {
            name: 'Adjusts',
            description: 'có thể điều chỉnh được quy trình, có khả năng lắng nghe phản hồi và hướng dẫn team thực thi quy trình và trải qua những thay đổi'
        },
        5: {
            name: 'Defines',
            description: 'định nghĩa ra được những quy trình cần thiết tùy theo độ chín của team, cân bằng giữa linh hoạt và kỷ luật'
        },
    },
    'influence': {
        1: {
            name: 'Subsystem',
            description: 'có ảnh hưởng tới một hay một vài hệ thống con (M)'
        },
        2: {
            name: 'Team',
            description: 'có ảnh hưởng tới cả team, không chỉ là một phần trong nó'
        },
        3: {
            name: 'Multiple Teams',
            description: 'có sức ảnh hưởng lớn tới các team khác nhau chứ không phải chỉ team của mình'
        },
        4: {
            name: 'Company',
            description: 'có sức ảnh hưởng tới cấp độ công ty'
        },
        5: {
            name: 'Community',
            description: 'có sức ảnh hưởng tới cấp độ cộng đồng'
        },
    },
};

const initialValues = {
    technology: 1,
    system: 1,
    product: 1,
    people: 1,
    process: 1,
    influence: 1
};

const jobLevel = [
    {
        name: 'Fresher/Junior',
        requirement: {
            technology: 1,
            system: 1,
            product: 1,
            people: 1,
            process: 1,
            influence: 1
        }
    },
    {
        name: 'Junior 2',
        requirement: {
            technology: 1,
            system: 2,
            product: 1,
            people: 2,
            process: 1,
            influence: 1
        }
    },
    {
        name: 'Junior 3',
        requirement: {
            technology: 2,
            system: 2,
            product: 1,
            people: 2,
            process: 2,
            influence: 1
        }
    },
    {
        name: 'Semi Senior',
        requirement: {
            technology: 2,
            system: 2,
            product: 2,
            people: 2,
            process: 2,
            influence: 2
        }
    },
    {
        name: 'Senior',
        requirement: {
            technology: 3,
            system: 3,
            product: 2,
            people: 3,
            process: 2,
            influence: 2
        }
    },
    {
        name: 'Senior 2',
        requirement: {
            technology: 3,
            system: 4,
            product: 3,
            people: 3,
            process: 3,
            influence: 3
        }
    },
    {
        name: 'Senior 3',
        requirement: {
            technology: 4,
            system: 4,
            product: 3,
            people: 4,
            process: 3,
            influence: 4
        }
    },
    {
        name: 'Expert',
        requirement: {
            technology: 5,
            system: 5,
            product: 3,
            people: 4,
            process: 3,
            influence: 5
        }
    },
    {
        name: 'Senior Expert',
        requirement: {
            technology: 6,
            system: 6,
            product: 3,
            people: 4,
            process: 3,
            influence: 5
        }
    }
].reverse();

const attributeName = {
    technology: 'Coding & Technology',
    system: 'System & Architecture',
    product: 'Product & Business',
    people: 'People',
    process: 'Process',
    influence: 'Influence'
};

export default class CareerLadder extends React.Component {
    onFinish = () => {

    };

    handleChange = () => {

    };

    valueChange = (changedValues, allValues) => {
        let attributes = Object.assign(this.state.attributes, changedValues);
        console.info(attributes);
        this.setState({
            attributes: attributes
        });
    };

    constructor(props) {
        super(props);

        this.state = {
            attributes: initialValues
        }
    }

    renderLevel(attribute) {
        if (levels[attribute][this.state.attributes[attribute]])
            return <div>{levels[attribute][this.state.attributes[attribute]].name}: {levels[attribute][this.state.attributes[attribute]].description}</div>
        else {
            console.error(attribute, this.state.attributes[attribute], levels[attribute])
        }
    }

    renderJobLevel = () => {
        let jobLevels = jobLevel;
        let jobIndex = jobLevels.findIndex((job, index) => {
            return job.requirement.technology <= this.state.attributes.technology
                && job.requirement.system <= this.state.attributes.system
                && job.requirement.product <= this.state.attributes.product
                && job.requirement.people <= this.state.attributes.people
                && job.requirement.process <= this.state.attributes.process
                && job.requirement.influence <= this.state.attributes.influence
        });
        let job = jobLevels[jobIndex];
        let nextJob = jobLevels[jobIndex - 1];
        let lacking = {};

        for (let attribute in this.state.attributes) {
            if (this.state.attributes.hasOwnProperty(attribute)) {
                let lackingNumber = nextJob.requirement[attribute] - this.state.attributes[attribute];
                if (lackingNumber > 0) {
                    lacking[attribute] = lackingNumber;
                }
            }
        }

        let data = [
            { "item": attributeName['technology'], "You": this.state.attributes['technology'], "Next Rank": nextJob.requirement['technology'] },
            { "item": attributeName['system'], "You": this.state.attributes['system'], "Next Rank": nextJob.requirement['system'] },
            { "item": attributeName['product'], "You": this.state.attributes['product'], "Next Rank": nextJob.requirement['product'] },
            { "item": attributeName['people'], "You": this.state.attributes['people'], "Next Rank": nextJob.requirement['people'] },
            { "item": attributeName['process'], "You": this.state.attributes['process'], "Next Rank": nextJob.requirement['process'] },
            { "item": attributeName['influence'], "You": this.state.attributes['influence'], "Next Rank": nextJob.requirement['influence'] },
        ];

        const { DataView } = DataSet;
        const dv = new DataView().source(data);
        dv.transform({
            type: 'fold',
            fields: ['You', 'Next Rank'], // 展开字段集
            key: 'user', // key字段
            value: 'score', // value字段
        });

        const config = {
            data: dv.rows,
            xField: 'item',
            yField: 'score',
            seriesField: 'user',
            meta: {
                score: {
                    alias: 'Tiêu chí',
                    min: 0,
                    max: 6,
                },
            },
            xAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        style: {
                            lineDash: null,
                        },
                    },
                },
            },
            yAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        type: 'line',
                        style: {
                            lineDash: null,
                        },
                    },
                    alternateColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
            // 开启辅助点
            point: {},
        };

        return <div>
            <div>
                Với đánh giá như trên thì bạn sẽ thuộc rank <strong>"{job.name}" (Rank {9-jobIndex})</strong>
                {jobIndex < 2 ? <span> (Ờ mây zing, gút chóp, bạn quả thực là nhân tài ẩn dật)</span>: null}
            </div>
            <div>
                Các chỉ số còn thiếu để lên rank tiếp theo: {Object.keys(lacking).map((key)=> {
                return <strong style={{marginRight: '15px'}}>{attributeName[key]}</strong>
            })}
            </div>
            <div>
                <Radar {...config} />;
            </div>
        </div>
    };

    render() {
        return <StandardLayout {...this.props}>
            <h1>Tự đánh giá Career Ladder</h1>
            <section className="dashboard">
                <Row>
                    <Col span={8}>
                        <Form
                            name="normal_login"
                            className="login-form"
                            onFinish={this.onFinish}
                            onValuesChange={this.valueChange}
                            {...layout}
                        >
                            <Form.Item
                                label={"Coding & Technology"}
                                name="technology"
                                rules={[{required: true, message: 'Select your technology level!'}]}
                            >
                                <Select defaultValue="1" style={{width: 120}}>
                                    <Option value="1">Adopts</Option>
                                    <Option value="2">Specializes</Option>
                                    <Option value="3">Evangelizes</Option>
                                    <Option value="4">Masters</Option>
                                    <Option value="5">Creates</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={"System & Architecture"}
                                name="system"
                                rules={[{required: true, message: 'Select your system level!'}]}
                            >
                                <Select defaultValue="1" style={{width: 120}}>
                                    <Option value="1">Enhances</Option>
                                    <Option value="2">Designs</Option>
                                    <Option value="3">Owns</Option>
                                    <Option value="4">Evolves</Option>
                                    <Option value="5">Leads</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={"Product & Business"}
                                name="product"
                                rules={[{required: true, message: 'Select your product level!'}]}
                            >
                                <Select defaultValue="1" style={{width: 120}}>
                                    <Option value="1">Copycat</Option>
                                    <Option value="2">Solves</Option>
                                    <Option value="3">Processes</Option>
                                    <Option value="4">Commercials</Option>
                                    <Option value="5">Creates</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={"People"}
                                name="people"
                                rules={[{required: true, message: 'Select your people level!'}]}
                            >
                                <Select defaultValue="1" style={{width: 120}}>
                                    <Option value="1">Learns</Option>
                                    <Option value="2">Supports</Option>
                                    <Option value="3">Mentors</Option>
                                    <Option value="4">Coordinates</Option>
                                    <Option value="5">Manages</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={"Process"}
                                name="process"
                                rules={[{required: true, message: 'Select your process level!'}]}
                            >
                                <Select defaultValue="1" style={{width: 120}}>
                                    <Option value="1">Follows</Option>
                                    <Option value="2">Enforces</Option>
                                    <Option value="3">Challenges</Option>
                                    <Option value="4">Adjusts</Option>
                                    <Option value="5">Defines</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={"Influence"}
                                name="influence"
                                rules={[{required: true, message: 'Select your influence level!'}]}
                            >
                                <Select defaultValue="1" style={{width: 120}}>
                                    <Option value="1">Subsystem</Option>
                                    <Option value="2">Team</Option>
                                    <Option value="3">Multiple Teams</Option>
                                    <Option value="4">Company</Option>
                                    <Option value="5">Community</Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={16}>
                        <Alert
                            message="Diễn giải level"
                            description={
                                <div>
                                    <div><strong>Coding & Technology</strong></div>
                                    {this.renderLevel('technology')}
                                    <div><strong>System & Architecture</strong></div>
                                    {this.renderLevel('system')}
                                    <div><strong>Product & Business</strong></div>
                                    {this.renderLevel('product')}
                                    <div><strong>People</strong></div>
                                    {this.renderLevel('people')}
                                    <div><strong>Process</strong></div>
                                    {this.renderLevel('process')}
                                    <div><strong>Influence</strong></div>
                                    {this.renderLevel('influence')}
                                </div>
                            }
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
                <Row>
                    {this.renderJobLevel()}
                </Row>
            </section>
        </StandardLayout>
    }
}